import { GameOverGameState, Model, User } from '@kfiroo/backgammon-core'
import { DocumentReference } from 'firebase/firestore'
import { last } from 'lodash'

import { BaseGameApi, BoardInfo } from './BaseGameApi'

export class GameOverGameApi extends BaseGameApi<GameOverGameState> {
  constructor(ref: DocumentReference, model: Model, user: User) {
    super(ref, model, user)
  }

  getBoard(): BoardInfo {
    const state = last(this.model.history)
    return this.buildBoardInfoFromState(state)
  }

  isWinner(): boolean {
    return this.gameState.winner === this.playerIndex
  }

  getWinner(): User {
    return this.model.players[this.gameState.winner] as User
  }

  getWinType(): GameOverGameState['winType'] {
    return this.gameState.winType
  }

  isLoser() {
    return this.playerIndex !== -1 && !this.isWinner()
  }
}
