import {
  type AvailableMoves,
  type GameRules,
  type Model,
  type Move,
  type User,
} from './Model'
import { rollGameId } from './random'
import { getRules } from './rules'
import { addUserToPlayers, isGameFull, isUserInGame } from './players'
import { getGameOverGameState, isGameOver } from './common'
import { cloneDeep, isNil } from 'lodash'

export function createGame(rules: GameRules): Model {
  return {
    gameId: rollGameId(),
    rules,
    players: [null, null],
    gameState: {
      state: 'created',
    },
    history: [],
  }
}

export function joinGame(model: Model, user: User): Model {
  if (model.gameState.state !== 'created') {
    throw new Error('Game is not in created state')
  }
  if (isGameFull(model.players)) {
    throw new Error('Game is full')
  }
  if (isUserInGame(model.players, user)) {
    throw new Error('User is already in game')
  }
  const players = addUserToPlayers(model.players, user)
  const newModel = {
    ...model,
    players,
  }
  if (isGameFull(newModel.players)) {
    return startGame(newModel)
  }
  return newModel
}

function startGame(model: Model): Model {
  if (model.gameState.state !== 'created') {
    throw new Error('Game is not in created state')
  }
  if (!isGameFull(model.players)) {
    throw new Error('Game is not full')
  }
  const rules = getRules(model.rules)
  return {
    ...model,
    gameState: rules.getInitialGameState(),
    history: [],
  }
}

export function playTurn(model: Model, moves: Move[]): Model {
  if (model.gameState.state !== 'in-progress') {
    throw new Error('Game is not in progress')
  }

  // add current state to history
  const history = [...model.history, model.gameState]

  const rules = getRules(model.rules)

  // calculate new state
  const stateWithMoves = rules.playTurnMoves(model.gameState, moves)

  if (isGameOver(stateWithMoves)) {
    return {
      ...model,
      gameState: getGameOverGameState(stateWithMoves),
      history,
    }
  }

  // calculate next turn
  const nextTurnState = rules.finalizeTurn(stateWithMoves)

  return {
    ...model,
    gameState: nextTurnState,
    history,
  }
}

export function playMoves(
  model: Model,
  moves: Move[],
  steps: number[],
): { model: Model; steps: number[] } {
  if (model.gameState.state !== 'in-progress') {
    throw new Error('Game is not in progress')
  }
  const rules = getRules(model.rules)

  const newState = moves.reduce(
    (s, m) => rules.playSingleMove(s, m),
    cloneDeep(model.gameState),
  )

  const newModel = {
    ...model,
    gameState: newState,
  }

  const newSteps = rules.updateSteps(model.gameState, moves, steps)

  return {
    model: newModel,
    steps: newSteps,
  }
}

export function rollDice(model: Model): Model {
  if (model.gameState.state !== 'in-progress') {
    throw new Error('Game is not in progress')
  }
  if (!isNil(model.gameState.dice)) {
    throw new Error('Dice already rolled')
  }
  const rules = getRules(model.rules)
  const newState = rules.rollDice(model.gameState)
  return {
    ...model,
    gameState: newState,
  }
}

export function getMovesWithSteps(
  model: Model,
  steps: number[],
): AvailableMoves {
  if (model.gameState.state !== 'in-progress') {
    throw new Error('Game is not in progress')
  }
  const rules = getRules(model.rules)
  return rules.getMovesWithSteps(model.gameState, steps)
}
