import { WHITE, BLACK, type Model, type User, type Player } from './Model'

type Players = Model['players']

export const isGameFull = (players: Players): boolean =>
  players[WHITE] !== null && players[BLACK] !== null

export const isUserInGame = (players: Players, user: User): boolean =>
  players.some((player) => player?.email === user.email)

export const addUserToPlayers = (players: Players, user: User): Players => {
  if (players[WHITE] === null) {
    return [user, players[BLACK]]
  }
  if (players[BLACK] === null) {
    return [players[WHITE], user]
  }
  throw new Error('Game is full')
}

export const opponent = (player: Player): Player =>
  player === WHITE ? BLACK : WHITE
