import {
  BAR_POINT,
  BLACK,
  type InProgressGameState,
  type Move,
  OFF_POINT,
  type Player,
  WHITE,
} from './Model'
import { opponent } from './players'

type Board = InProgressGameState['board']

export const getInitialPoints = (): Board[Player] => ({
  [OFF_POINT]: 0,
  6: 5,
  8: 3,
  13: 5,
  24: 2,
  [BAR_POINT]: 0,
})

export const getInitialBoard = (): Board => ({
  [WHITE]: getInitialPoints(),
  [BLACK]: getInitialPoints(),
})

export const getPointsWithAtLeast = (
  board: Board,
  player: Player,
  minCount: number,
): number[] => {
  const points: number[] = []
  for (let point = BAR_POINT; point > OFF_POINT; point--) {
    if (countAtPoint(board, player, point) >= minCount) {
      points.push(point)
    }
  }
  // console.log('points', JSON.stringify(points, null, 4))

  return points
}

export const opponentPoint = (point: number | string): number =>
  25 - Number(point)

export const countAtPoint = (
  board: Board,
  player: Player,
  point: number,
): number => board[player][point] ?? 0

// has more than one checker
export const isOccupied = (
  board: Board,
  player: Player,
  point: number,
): boolean =>
  point !== OFF_POINT &&
  point !== BAR_POINT &&
  countAtPoint(board, player, point) > 1

export const isHit = (board: Board, player: Player, move: Move): boolean =>
  move.to !== OFF_POINT &&
  move.to !== BAR_POINT &&
  countAtPoint(board, opponent(player), opponentPoint(move.to)) === 1

export function isReadyToBearOff(board: Board, player: Player): boolean {
  const playerPoints = getPointsWithAtLeast(board, player, 1)
  return playerPoints.every((p) => p < 7)
}

export function isOnBar(board: Board, player: Player): boolean {
  return countAtPoint(board, player, BAR_POINT) > 0
}

export function doMove(board: Board, player: Player, move: Move): void {
  board[player][move.from] -= 1
  board[player][move.to] = board[player][move.to] ?? 0
  board[player][move.to] += 1
  if (isHit(board, player, move)) {
    // doMove(state.board, opponent(state.turn), opponentPoint(move.to), BAR_POINT)
    board[opponent(player)][opponentPoint(move.to)] -= 1
    board[opponent(player)][BAR_POINT] = board[opponent(player)][BAR_POINT] ?? 0
    board[opponent(player)][BAR_POINT] += 1
  }
  cleanup(board)
}

function cleanup(board: Board): void {
  for (let i = 1; i < BAR_POINT; i++) {
    if (board[WHITE][i] === 0) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete board[WHITE][i]
    }
    if (board[BLACK][i] === 0) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete board[BLACK][i]
    }
  }
}
