import { getMessaging, getToken, isSupported } from 'firebase/messaging'

const vapidKey =
  'BGH7YAr_WJ0iq1v_x74wg5S_4vlOUB9tw1vJF6GwQtH74a4U4ROHu77dgW4IoUv7vlsBPYpzMXlaeQ2JHHGl4Tg'

export interface TokenResult {
  success: boolean
  token?: string
  error?: string
}

export async function requestToken(): Promise<TokenResult> {
  const messaging = getMessaging()

  try {
    const isSupportedResult = await isSupported()
    if (!isSupportedResult) {
      console.log('Messaging is not supported')
      return {
        success: false,
        error: 'Messaging is not supported',
      }
    }
    console.log('trying to get token')
    const currentToken = await getToken(messaging, {
      vapidKey,
    })

    if (currentToken) {
      console.log('current token for client: ', currentToken)
      // Perform any other necessary action with the token
      return {
        success: true,
        token: currentToken,
      }
    }
    // Show permission request UI
    console.log(
      'No registration token available. Request permission to generate one.',
    )
    return {
      success: false,
      error: 'Failed to get token - no permission',
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err)
    return {
      success: false,
      error:
        'Failed to get token - ' + (err as Error).message ||
        String(err) ||
        'unknown error',
    }
  }
}
