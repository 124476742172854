import {
  BAR_POINT,
  GameState,
  InProgressGameState,
  Model,
  OFF_POINT,
  opponent,
  opponentPoint,
  Player,
  User,
} from '@kfiroo/backgammon-core'
import { DocumentReference, setDoc } from 'firebase/firestore'
import { cloneDeep } from 'lodash'

export enum PlayerType {
  PLAYER = 'player',
  OPPONENT = 'opponent',
}

interface LaneInfo {
  checkers: number
  owner: PlayerType
}

export interface BoardInfo {
  lanes: Record<string, LaneInfo>
  bar: Record<PlayerType, number>
  off: Record<PlayerType, number>
}

export type PlayersInfo = Record<PlayerType, User | null>

export abstract class BaseGameApi<StateType extends GameState> {
  protected model: Model
  protected readonly playerIndex: number

  protected constructor(
    protected readonly ref: DocumentReference,
    protected initialModel: Model,
    protected readonly user: User,
  ) {
    this.model = cloneDeep(initialModel)
    this.playerIndex = this.model.players.findIndex(
      (u) => u?.email === this.user.email,
    )
  }

  get gameId(): string {
    return this.model.gameId
  }

  abstract getBoard(): BoardInfo

  protected buildBoardInfoFromState(state?: InProgressGameState): BoardInfo {
    if (state?.state === 'in-progress') {
      const playerIndex = (
        this.playerIndex === -1 ? 0 : this.playerIndex
      ) as Player
      const opponentIndex = opponent(playerIndex)

      const playerBoard = state.board[playerIndex]
      const opponentBoard = state.board[opponentIndex]

      const lanes: BoardInfo['lanes'] = {}
      Object.entries(playerBoard).forEach(([point, count]) => {
        if (count) {
          lanes[point] = {
            owner: PlayerType.PLAYER,
            checkers: count,
          }
        }
      })
      Object.entries(opponentBoard).forEach(([point, count]) => {
        if (count) {
          lanes[opponentPoint(point)] = {
            owner: PlayerType.OPPONENT,
            checkers: count,
          }
        }
      })
      const bar: BoardInfo['bar'] = {
        [PlayerType.PLAYER]: playerBoard[BAR_POINT] ?? 0,
        [PlayerType.OPPONENT]: opponentBoard[BAR_POINT] ?? 0,
      }

      const off: BoardInfo['off'] = {
        [PlayerType.PLAYER]: playerBoard[OFF_POINT] ?? 0,
        [PlayerType.OPPONENT]: opponentBoard[OFF_POINT] ?? 0,
      }

      return {
        lanes,
        bar,
        off,
      }
    }
    return {
      lanes: {},
      bar: {
        [PlayerType.PLAYER]: 0,
        [PlayerType.OPPONENT]: 0,
      },
      off: {
        [PlayerType.PLAYER]: 0,
        [PlayerType.OPPONENT]: 0,
      },
    }
  }

  protected get gameState(): StateType {
    return this.model.gameState as StateType
  }

  getPlayers(): PlayersInfo {
    if (this.playerIndex === -1) {
      return {
        [PlayerType.PLAYER]: this.model.players[0],
        [PlayerType.OPPONENT]: this.model.players[1],
      }
    }
    return {
      [PlayerType.PLAYER]: this.model.players[this.playerIndex],
      [PlayerType.OPPONENT]: this.model.players[1 - this.playerIndex],
    }
  }

  protected saveGameModel(model?: Model): void {
    try {
      console.log('saveGameModel', model ?? this.model)
      setDoc(this.ref, model ?? this.model)
        .then(() => {
          console.log('saveGameModel - success')
        })
        .catch((err) => {
          console.error('saveGameModel', err)
        })
    } catch (err) {
      console.error('saveGameModel', err)
    }
  }
}
