export const firebaseConfig = {
  apiKey: 'AIzaSyB2G7ClKYnF4_q7rlDoJLQhwgdkWLcb7vQ',
  authDomain: 'roroyal-65.firebaseapp.com',
  databaseURL:
    'https://roroyal-65-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'roroyal-65',
  storageBucket: 'roroyal-65.appspot.com',
  messagingSenderId: '1001587205852',
  appId: '1:1001587205852:web:8b28ff3ed48e453a0a3619',
  measurementId: 'G-2PVEZZP01Q',
}
