import { User } from '@kfiroo/backgammon-core'
import { GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
import { SigninCheckResult, useAuth, useSigninCheck } from 'reactfire'

export interface AuthResult {
  user?: User
  loading: boolean
  errors: SigninCheckResult['errors']
  signInWithGoogle: () => Promise<void>
}

export function useFirebaseAuth(): AuthResult {
  const auth = useAuth()
  const { status, data: signInCheckResult } = useSigninCheck()

  // @ts-expect-error - for debugging
  window.signOut = () => signOut(auth)

  async function signInWithGoogle() {
    try {
      const provider = new GoogleAuthProvider()
      const credentials = await signInWithPopup(auth, provider)
      console.log('signInWithGoogle', credentials)
    } catch (err) {
      console.error('signInWithGoogle', err)
    }
  }

  return {
    signInWithGoogle,
    user: makeUser(signInCheckResult),
    loading: status === 'loading',
    errors: signInCheckResult?.errors ?? [],
  }
}

function makeUser(signInCheckResult: SigninCheckResult): User | undefined {
  if (
    !signInCheckResult ||
    !signInCheckResult.signedIn ||
    !signInCheckResult.user
  ) {
    return undefined
  }
  const { uid, email, displayName, photoURL } = signInCheckResult.user
  if (!email || !displayName || !photoURL) {
    return undefined
  }
  return {
    uid,
    email,
    displayName,
    photoURL,
  }
}
