import { Dice, User } from '@kfiroo/backgammon-core'
import { noop } from 'lodash'
import React from 'react'

import { DiceComponent } from './Dice'
import { PlayersInfo, PlayerType } from './GameApi/BaseGameApi'

export interface PlayersProps {
  players: PlayersInfo
  turn?: PlayerType | null
  dice?: Dice | null
  onEndTurn?: () => void
  endTurnEnabled?: boolean
  onUndo?: () => void
  undoEnabled?: boolean
  onRoll?: () => void
}

export function Players({
  players,
  turn = null,
  dice = null,
  onEndTurn = noop,
  endTurnEnabled = false,
  onUndo = noop,
  undoEnabled = false,
  onRoll = noop,
}: PlayersProps) {
  return (
    <div className="players">
      <div className="player">
        <PlayerInfo
          user={players.player}
          isPlaying={turn === PlayerType.PLAYER}
          dice={dice}
          onRoll={onRoll}
        />
      </div>

      <div className="buttons">
        <button disabled={!undoEnabled} onClick={onUndo}>
          Undo
        </button>
        <button disabled={!endTurnEnabled} onClick={onEndTurn}>
          End Turn
        </button>
      </div>

      <div className="opponent">
        <PlayerInfo
          user={players.opponent}
          isPlaying={turn === PlayerType.OPPONENT}
          dice={dice}
        />
      </div>
    </div>
  )
}

export interface PlayerProps {
  user: User | null
  isPlaying: boolean
  dice?: Dice | null
  onRoll?: () => void
}

export function PlayerInfo({ user, isPlaying, dice, onRoll }: PlayerProps) {
  return (
    <div className={isPlaying ? 'playing' : ''}>
      {user && <img className="avatar" src={user.photoURL} alt="avatar" />}
      {user ? (
        <div className="name">{user.displayName}</div>
      ) : (
        <div>Waiting...</div>
      )}
      <DiceView isPlaying={isPlaying} dice={dice} onRoll={onRoll} />
    </div>
  )
}

export function DiceView({
  dice,
  isPlaying,
  onRoll,
}: {
  dice?: Dice | null
  isPlaying: boolean
  onRoll?: () => void
}) {
  return (
    <div className="dice-container">
      {isPlaying && !dice && onRoll ? (
        <button onClick={() => onRoll()}>Roll</button>
      ) : null}
      {isPlaying && dice ? (
        <DiceComponent value={dice?.die1 ?? 0} size={50} />
      ) : null}
      {isPlaying && dice ? (
        <DiceComponent value={dice?.die2 ?? 0} size={50} />
      ) : null}
    </div>
  )
}
