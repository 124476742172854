import './App.css'

import { getAuth } from 'firebase/auth'
import { initializeFirestore } from 'firebase/firestore'
import { getMessaging, onMessage } from 'firebase/messaging'
import React, { useEffect } from 'react'
import {
  AuthProvider,
  FirestoreProvider,
  useFirebaseApp,
  useInitFirestore,
  useInitPerformance,
} from 'reactfire'

import { Analytics } from './Analytics'
import { Router } from './Router'

function App() {
  const firebaseApp = useFirebaseApp()
  const auth = getAuth(firebaseApp)
  const messaging = getMessaging(firebaseApp)

  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import('firebase/performance')
    return getPerformance(firebaseApp)
  })

  const { status, data: firestoreInstance } = useInitFirestore(
    async (firebaseApp) => {
      return initializeFirestore(firebaseApp, {})
    },
  )

  useEffect(() => {
    console.log('Registering local notification handler')
    onMessage(messaging, (payload) => {
      console.log('Message received', payload)
      // ...
    })
  }, [])

  if (status === 'loading') {
    return <div>Initializing firestore...</div>
  }

  return (
    <>
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={firestoreInstance}>
          <Router />
        </FirestoreProvider>
      </AuthProvider>
      <Analytics />
    </>
  )
}

export default App
