import {
  CreatedGameState,
  joinGame,
  Model,
  User,
} from '@kfiroo/backgammon-core'
import { DocumentReference } from 'firebase/firestore'

import { BaseGameApi, BoardInfo, PlayerType } from './BaseGameApi'

export class CreatedGameApi extends BaseGameApi<CreatedGameState> {
  constructor(ref: DocumentReference, model: Model, user: User) {
    super(ref, model, user)
  }

  getBoard(): BoardInfo {
    return {
      lanes: {},
      bar: {
        [PlayerType.PLAYER]: 0,
        [PlayerType.OPPONENT]: 0,
      },
      off: {
        [PlayerType.PLAYER]: 0,
        [PlayerType.OPPONENT]: 0,
      },
    }
  }

  joinGame() {
    console.log('joinGame', this.user)
    try {
      this.model = joinGame(this.model, this.user)
      this.saveGameModel()
    } catch (err) {
      console.error('joinGame', err)
    }
  }
}
