import { type GameRules } from '../Model'
import { type Rules } from './Rules'
import { standardRules } from './standard'
import { roroRules } from './roro'

export const getRules = (rulesName: GameRules): Rules => {
  switch (rulesName) {
    case 'standard':
      return standardRules
    case 'roro':
      return roroRules
  }
}
