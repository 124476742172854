import './index.css'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { FirebaseAppProvider } from 'reactfire'

import App from './App'
import { firebaseConfig } from './firebase/firebase'

const rootElement = document.getElementById('root')
if (!rootElement) {
  throw new Error('Could not find root element')
}

const root = createRoot(rootElement)

root.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>
  </React.StrictMode>,
)
