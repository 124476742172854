import React, { useCallback, useEffect, useState } from 'react'

import { Board } from './Board'
import { CreatedGameApi } from './GameApi/CreatedGameApi'
import { Players } from './Players'

export function CreatedGame({ gameApi }: { gameApi: CreatedGameApi }) {
  const [inviteText, setInviteText] = useState<string>('Invite')

  const shareSupported = navigator.share !== undefined

  useEffect(() => {
    gameApi.joinGame()
    setInviteText(shareSupported ? 'Invite' : 'Copy invite link')
  }, [])

  const invite = useCallback(() => {
    if (shareSupported) {
      console.log('Share supported')
      navigator
        .share({
          title: 'Roroyal Backgammon',
          text: 'Join my game!',
          url: window.location.href,
        })
        .then(() => {
          console.log('Thanks for sharing!')
        })
        .catch((err) => {
          console.error(err)
        })
    } else {
      console.log('Share not supported')
      navigator.clipboard.writeText(window.location.href)
      setInviteText('Copied!')
    }
  }, [])

  return (
    <div className="game">
      <Players players={gameApi.getPlayers()} />
      <Board board={gameApi.getBoard()} />
      <div className="waiting">
        <p style={{ padding: 20, textAlign: 'center' }}>
          Waiting for another player to join the game...
        </p>
        <button className="share" onClick={invite}>
          {inviteText}
        </button>
        <button onClick={() => window.location.assign(`/`)}>Back</button>
      </div>
    </div>
  )
}
