import { AvailableMoves, Dice } from '@kfiroo/backgammon-core'
import { isNumber, last, noop, uniq } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import { TokenResult } from '../firebase/messaging'
import { Board } from './Board'
import { BoardInfo, PlayerType } from './GameApi/BaseGameApi'
import {
  InProgressGameApi,
  PlayingGameApi,
  ReadOnlyGameApi,
} from './GameApi/InProgressGameApi'
import { Players } from './Players'

interface GameLogic {
  dropTargets?: number[]
  hitTargets?: number[]
  selected: number | null
  onLaneClicked: (lane: number) => void

  board: BoardInfo
  turn: PlayerType | null
  dice: Dice | null
  endTurnEnabled: boolean
  onEndTurn: () => void
  undoEnabled: boolean
  onUndo: () => void
  onRoll: () => void
}

function usePlayingGameLogic(gameApi: PlayingGameApi): GameLogic {
  const [board, setBoard] = useState<BoardInfo>(gameApi.getBoard())
  const [selected, setSelected] = useState<number | null>(null)
  const [endTurnEnabled, setEndTurnEnabled] = useState(false)
  const [undoEnabled, setUndoEnabled] = useState(false)

  const availableMoves = gameApi.getValidMoves()
  const dropTargets = calcDropTargets(selected, availableMoves)
  const hitTargets = dropTargets.filter((lane) => gameApi.isOpenOpponent(lane))

  useEffect(() => {
    setBoard(gameApi.getBoard())
    setSelected(null)
    setEndTurnEnabled(gameApi.canEndTurn())
    setUndoEnabled(gameApi.canUndo())
  }, [gameApi])

  const onLaneClicked = useCallback(
    (lane: number) => {
      if (selected === null) {
        console.log('select', lane)
        if (availableMoves[lane]?.length) {
          setSelected(lane)
        }
        return
      }
      if (selected === lane) {
        console.log('unselect', lane)
        setSelected(null)
        return
      }
      if (dropTargets.includes(lane)) {
        console.log('move', selected, lane)
        gameApi.move(selected, lane)
        setUndoEnabled(gameApi.canUndo())
        setEndTurnEnabled(gameApi.canEndTurn())
        setBoard(gameApi.getBoard())
        setSelected(null)
      }
    },
    [selected, endTurnEnabled, availableMoves, gameApi, dropTargets],
  )

  const onEndTurn = useCallback(() => {
    console.log('end turn')
    gameApi.endTurn()
    setUndoEnabled(gameApi.canUndo())
    setEndTurnEnabled(gameApi.canEndTurn())
  }, [endTurnEnabled, gameApi])

  const onUndo = useCallback(() => {
    console.log('undo')
    gameApi.undo()
    setUndoEnabled(gameApi.canUndo())
    setEndTurnEnabled(gameApi.canEndTurn())
    setBoard(gameApi.getBoard())
  }, [undoEnabled, gameApi])

  const onRoll = useCallback(() => {
    console.log('roll')
    gameApi.rollDice()
  }, [gameApi])

  return {
    board,
    turn: gameApi.getTurn(),
    dice: gameApi.getDice(),
    hitTargets,
    dropTargets,
    selected,
    onLaneClicked,
    endTurnEnabled,
    onEndTurn,
    undoEnabled,
    onUndo,
    onRoll,
  }
}

function useReadOnlyGameLogic(gameApi: ReadOnlyGameApi): GameLogic {
  return {
    board: gameApi.getBoard(),
    turn: gameApi.getTurn(),
    dice: gameApi.getDice(),
    hitTargets: [],
    dropTargets: [],
    selected: null,
    onLaneClicked: noop,
    endTurnEnabled: false,
    onEndTurn: noop,
    undoEnabled: false,
    onUndo: noop,
    onRoll: noop,
  }
}

function useGameLogic(gameApi: InProgressGameApi): GameLogic {
  if (gameApi instanceof PlayingGameApi) {
    return usePlayingGameLogic(gameApi)
  }
  return useReadOnlyGameLogic(gameApi)
}

export function InProgressGame({ gameApi }: { gameApi: InProgressGameApi }) {
  const {
    board,
    turn,
    dice,
    dropTargets,
    hitTargets,
    selected,
    onLaneClicked,
    onEndTurn,
    endTurnEnabled,
    onUndo,
    undoEnabled,
    onRoll,
  } = useGameLogic(gameApi)

  const [notifications, setNotifications] = useState<TokenResult>({
    success: false,
    error: 'init',
  })

  useEffect(() => {
    gameApi
      .setupNotifications()
      .then((res) => {
        console.log('notifications setup')
        setNotifications(res)
      })
      .catch((err) => {
        console.error('notifications setup error', err)
        setNotifications({
          success: false,
          error: err.message,
        })
      })

    // clear notifications on visibility change
    async function handleVisibilityChange() {
      if (document.visibilityState !== 'visible') {
        return
      }

      const registrations = await navigator.serviceWorker.getRegistrations()
      const registration = registrations[0]
      if (!registration) {
        console.log('ServiceWorker registration is missing', registrations)
        return
      }

      const allNotifications = await registration.getNotifications()
      console.log('all notifications', allNotifications)

      allNotifications.forEach(function (notification) {
        if (notification.tag === gameApi.gameId || notification.tag === '') {
          console.log('closing notification', notification)
          notification.close()
        }
      })
    }
    handleVisibilityChange()
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const showNotificationStatus = useCallback(() => {
    alert(
      [
        `Notifications are ${notifications?.success ? 'enabled' : 'disabled'}`,
        `\ndetails:`,
        `isIndexedDBAvailable: ${typeof indexedDB === 'object'}`,
        `areCookiesEnabled: ${navigator.cookieEnabled}`,
        `serviceWorker: ${'serviceWorker' in navigator}`,
        `PushManager: ${'PushManager' in window}`,
        `Notification: ${'Notification' in window}`,
        // eslint-disable-next-line no-prototype-builtins
        `ServiceWorkerRegistration.prototype.hasOwnProperty('showNotification') : ${ServiceWorkerRegistration?.prototype.hasOwnProperty(
          'showNotification',
        )}`,
        // eslint-disable-next-line no-prototype-builtins
        `PushSubscription.prototype.hasOwnProperty('getKey'): ${PushSubscription?.prototype.hasOwnProperty(
          'getKey',
        )}`,
        `notifications.error: ${notifications?.error}`,
      ].join('\n'),
    )
  }, [notifications])

  return (
    <div className={`game ${gameApi.getRules()}`}>
      <div className="header">
        <button className="back" onClick={() => window.location.assign(`/`)}>
          Back
        </button>
        <button
          className="notifications"
          onClick={() => showNotificationStatus()}
        >
          Notifications
        </button>
      </div>
      <Players
        players={gameApi.getPlayers()}
        turn={turn}
        dice={dice}
        onEndTurn={onEndTurn}
        endTurnEnabled={endTurnEnabled}
        onUndo={onUndo}
        undoEnabled={undoEnabled}
        onRoll={onRoll}
      />
      <Board
        board={board}
        selected={selected}
        onLaneClicked={onLaneClicked}
        dropTargets={dropTargets}
        hitTargets={hitTargets}
      />
    </div>
  )
}

function calcDropTargets(
  from: number | null,
  availableMoves: AvailableMoves,
): number[] {
  if (from === null || !availableMoves[from]) {
    return []
  }

  return uniq(
    availableMoves[from].map((moves) => last(moves)?.to).filter(isNumber),
  )
}
