import { type Rules } from './Rules'
import {
  addMoveInfo,
  diceToSteps,
  getAllMovesFromPoint,
  getInitialGameState,
} from '../common'
import {
  type AvailableMoves,
  BAR_POINT,
  type InProgressGameState,
  type Move,
  OFF_POINT,
} from '../Model'
import {
  countAtPoint,
  doMove,
  getPointsWithAtLeast,
  isOccupied,
  isOnBar,
  isReadyToBearOff,
  opponentPoint,
} from '../board'
import { opponent } from '../players'
import { cloneDeep, isNil } from 'lodash'
import { rollDice } from '../random'

export const standardRules: Rules = {
  getInitialGameState,

  getMovesWithSteps(state, steps) {
    if (steps.length === 0) {
      return {}
    }
    const playerPoints = getPointsWithAtLeast(state.board, state.turn, 1)

    const movesFromPoint = playerPoints.reduce<AvailableMoves>((acc, from) => {
      const allMoves = getAllMovesFromPoint(state, from, steps)

      const movesList = allMoves.filter((moves) => isValidMoves(state, moves))

      if (movesList.length > 0) {
        acc[from] = movesList.map((moves) =>
          moves.map((move) => addMoveInfo(move, state)),
        )
      }
      return acc
    }, {})

    return movesFromPoint
  },

  playTurnMoves(state, moves) {
    const steps = diceToSteps(state.dice)
    const expectedMoves = steps.length

    if (moves.length > expectedMoves) {
      throw new Error('Too many moves')
    }

    const newState = moves.reduce(
      (s, move) => applyMove(s, move),
      cloneDeep(state),
    )

    const newSteps = standardRules.updateSteps(state, moves, steps)

    if (moves.length < expectedMoves) {
      const availableMoves = standardRules.getMovesWithSteps(newState, newSteps)
      if (Object.keys(availableMoves).length > 0) {
        throw new Error('Not all moves were made')
      }
    }

    // console.log('newState', JSON.stringify(newState, null, 4))

    return newState
  },

  finalizeTurn(state) {
    return {
      ...state,
      turn: opponent(state.turn),
      dice: null,
    }
  },

  rollDice(state) {
    if (!isNil(state.dice)) {
      throw new Error('Dice already rolled')
    }
    const dice = rollDice()
    return {
      ...state,
      dice,
    }
  },

  playSingleMove(state, move) {
    return applyMove(state, move)
  },

  updateSteps(_state, moves, steps) {
    let newSteps = [...steps]
    moves.forEach((move) => {
      const si = newSteps.indexOf(move.step)
      newSteps = newSteps.filter((_, i) => i !== si)
    })
    return newSteps
  },
}

export function isValidMoves(
  state: InProgressGameState,
  moves: Move[],
): boolean {
  try {
    moves.reduce((s, move) => applyMove(s, move), cloneDeep(state))
    return true
  } catch {
    return false
  }
}

function applyMove(
  state: InProgressGameState,
  move: Move,
): InProgressGameState {
  // console.log('applyMove', JSON.stringify(move, null, 4))

  if (countAtPoint(state.board, state.turn, move.from) === 0) {
    throw new Error(
      `Invalid move player ${state.turn} no checkers at point ${move.from}`,
    )
  }
  if (move.from <= OFF_POINT) {
    throw new Error('Cannot move from off point')
  }
  if (isReadyToBearOff(state.board, state.turn)) {
    // all checkers are home
    const realTo = move.from - move.step
    if (realTo === OFF_POINT) {
      // remove exact dice
      doMove(state.board, state.turn, move)
      return state
    }
    const playerPoints = getPointsWithAtLeast(state.board, state.turn, 1)
    if (realTo < OFF_POINT) {
      // not exact dice - make sure there are no higher points
      const maxPoint = Math.max(...playerPoints)
      if (maxPoint > move.from) {
        throw new Error(
          `Cannot bear off from point (${move.from}) when a higher point (${maxPoint}) is available`,
        )
      }
    }
  } else {
    // not all checkers are home
    if (move.to <= OFF_POINT) {
      throw new Error('Not all checkers are home - cannot move to off point')
    }
    // from > OFF_POINT to > OFF_POINT
    if (isOnBar(state.board, state.turn) && move.from !== BAR_POINT) {
      // can only move from bar
      throw new Error('Player has checkers on bar - must recover first')
    }
  }

  if (isOccupied(state.board, opponent(state.turn), opponentPoint(move.to))) {
    throw new Error('Cannot move to occupied point')
  }

  doMove(state.board, state.turn, move)

  return state
}
