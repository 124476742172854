// single firebase document per game
export const WHITE = 0 as const
export const BLACK = 1 as const

export type Player = typeof WHITE | typeof BLACK

// guid or something
export type GameId = string

// firebase auth user - not sure that we need anything other than email
export interface User {
  uid: string
  email: string
  displayName: string
  photoURL: string
  messagingToken?: string
}

// roro is shesh besh aravi
export type GameRules = 'standard' | 'roro'

export interface Model {
  gameId: GameId
  rules: GameRules
  players: [User | null, User | null]
  gameState: GameState
  history: InProgressGameState[]
}

export type WinType = 'normal' | 'gammon' | 'backgammon'

export const OFF_POINT = 0 as const
export const BAR_POINT = 25 as const
// 0 - off
// 1-24 - points
// 25 - bar
export type Points = Record<number, number> & {
  [OFF_POINT]: number
  [BAR_POINT]: number
}

export interface Dice {
  die1: number
  die2: number
}

export interface CreatedGameState {
  state: 'created'
}

export interface GameOverGameState {
  state: 'game-over'
  winner: Player
  winType: WinType
}

export interface InProgressGameState {
  state: 'in-progress'
  turn: Player
  dice: Dice | null
  // board from player POV
  board: Record<Player, Points>
}

export type GameState =
  | CreatedGameState
  | GameOverGameState
  | InProgressGameState

export interface Move {
  from: number
  to: number
  step: number
}
export interface MoveInfo extends Move {
  isHit: boolean
  isBearOff: boolean
}

// from starting point to list of available moves
export type AvailableMoves = Record<number, MoveInfo[][]>
