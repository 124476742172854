import './Dice.css'

import { random, times } from 'lodash'
import { useEffect, useState } from 'react'

interface DiceProps {
  value: number
  size: number
}

export function DiceComponent({ value, size }: DiceProps) {
  const [ms, setMs] = useState(0)
  const [count, setCount] = useState(30)
  const [isAnimating, setIsAnimating] = useState(true)

  useEffect(() => {
    if (isAnimating) {
      const timer = setTimeout(() => {
        setCount(count - 1)
        setMs(ms + (30 / count + 1))
      }, ms)
      return () => clearTimeout(timer)
    }
  }, [count, isAnimating, ms])

  const diceValue = isAnimating ? random(1, 6) : value

  if (count === 0 && isAnimating) setIsAnimating(false)

  return (
    <div
      className={`dice dice-${diceValue}`}
      style={{ width: size, height: size }}
    >
      {times(6, (i) => (
        <div key={i} className={`dot dot-${i + 1}`} />
      ))}
    </div>
  )
}
