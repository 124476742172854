import React from 'react'

import { useFirebaseAuth } from './auth/useFirebaseAuth'
import { Game } from './Game/Game'
import { Lobby } from './Lobby'

export function Router() {
  const { user, loading, errors, signInWithGoogle } = useFirebaseAuth()

  if (loading) {
    return <div>Loading...</div>
  }

  const searchParams = new URLSearchParams(document.location.search)
  const gameId = searchParams.get('gameId')

  if (!gameId || !user) {
    return (
      <Lobby
        user={user}
        loading={loading}
        errors={errors}
        signInWithGoogle={signInWithGoogle}
      />
    )
  }

  return <Game gameId={gameId} user={user} />
}
