import React from 'react'

import { Board } from './Board'
import { Confetti } from './Confetti'
import { GameOverGameApi } from './GameApi/GameOverGameApi'
import { Players } from './Players'

export function GameOver({ gameApi }: { gameApi: GameOverGameApi }) {
  return (
    <div className="game">
      <Players players={gameApi.getPlayers()} />
      <Board board={gameApi.getBoard()} />
      <Overlay
        isWinner={gameApi.isWinner()}
        isLoser={gameApi.isLoser()}
        winnerName={gameApi.getWinner().displayName}
        winType={gameApi.getWinType()}
      />
    </div>
  )
}

function Overlay({
  isWinner,
  isLoser,
  winnerName,
  winType,
}: {
  isWinner: boolean
  isLoser: boolean
  winnerName: string
  winType: string
}) {
  if (isWinner) {
    return (
      <div className="waiting">
        <Confetti />
        <h1>Yay! You won! 🐒👑</h1>
        <h2>Win type: {winType}</h2>
        <button onClick={() => window.location.assign(`/`)}>Play again</button>
      </div>
    )
  }
  if (isLoser) {
    return (
      <div className="waiting">
        <h1>Sorry! You lost! 😭</h1>
        <h2>Win type: {winType}</h2>
        <button onClick={() => window.location.assign(`/`)}>Play again</button>
      </div>
    )
  }
  return (
    <div className="waiting">
      <h1>Winner: {winnerName}</h1>
      <h2>Win type: {winType}</h2>
      <button onClick={() => window.location.assign(`/`)}>Play again</button>
    </div>
  )
}
