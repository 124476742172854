import './Board.css'

import { BAR_POINT, OFF_POINT } from '@kfiroo/backgammon-core'
import { noop, times } from 'lodash'
import React from 'react'

import { BoardInfo, PlayerType } from './GameApi/BaseGameApi'

interface BoardProps {
  board: BoardInfo
  dropTargets?: number[]
  hitTargets?: number[]
  selected?: number | null
  onLaneClicked?: (lane: number) => void
}

export function Board({
  board,
  onLaneClicked = noop,
  selected = null,
  hitTargets = [],
  dropTargets = [],
}: BoardProps) {
  return (
    <div className="board-area">
      <div className="board">
        <div className="top-rail rail"></div>
        <div className="right-rail-board rail"></div>
        <div className="bottom-rail rail"></div>
        <div className="left-rail rail"></div>
        <div className="middle-rail-1 rail"></div>
        <div className="middle"></div>
        <div className="middle-rail-2 rail"></div>

        <Bar
          bar={board.bar}
          isSelected={selected === BAR_POINT}
          onClick={() => onLaneClicked(BAR_POINT)}
        />

        <Off
          off={board.off}
          isDropTarget={dropTargets?.includes(OFF_POINT)}
          onClick={() => onLaneClicked(OFF_POINT)}
        />

        <div className="right-rail-off rail"></div>
        {times(24, (i) => {
          const index = i + 1
          return (
            <Lane
              key={index}
              index={index}
              checkers={board.lanes[index]?.checkers ?? 0}
              owner={board.lanes[index]?.owner ?? null}
              onClick={() => onLaneClicked(index)}
              isSelected={selected === index}
              isDropTarget={dropTargets.includes(index)}
              isHitTarget={hitTargets.includes(index)}
            />
          )
        })}
      </div>
    </div>
  )
}

interface LaneProps {
  index: number
  checkers: number
  owner: PlayerType
  onClick: (lane: number) => void
  isSelected: boolean
  isDropTarget: boolean
  isHitTarget: boolean
}

function Lane({
  index,
  checkers,
  owner,
  onClick,
  isSelected,
  isDropTarget,
  isHitTarget,
}: LaneProps) {
  const classes = [`lane-${index}`]
  classes.push(index % 2 ? 'odd' : 'even')
  classes.push(index > 12 ? 'top' : 'bottom')
  if (isSelected) {
    classes.push('selected')
  }
  if (isDropTarget) {
    classes.push('drop-target')
  }
  if (isHitTarget) {
    classes.push('hit-target')
  }

  const displayedCheckers = Math.min(checkers, 5)

  return (
    <div
      className={['lane', ...classes].join(' ')}
      onClick={() => onClick(index)}
      role="button"
      aria-hidden
    >
      <div className="lane-index">{index}</div>
      <div className="lane-bg"></div>
      {times(displayedCheckers, (i) => {
        if (displayedCheckers < checkers && i === 4) {
          return <Checker key={i} owner={owner} label={checkers} />
        }
        return <Checker key={i} owner={owner} />
      })}
    </div>
  )
}

function Bar({
  bar,
  onClick,
  isSelected,
}: {
  bar: BoardInfo['bar']
  onClick: () => void
  isSelected: boolean
}) {
  return (
    <div className="bar-rail">
      <div className="bar-cages">
        <div
          className={`bar-cage-player ${isSelected ? 'selected' : ''}`}
          onClick={onClick}
          role="button"
          aria-hidden
        >
          {times(bar.player, (i) => (
            <Checker key={i} owner={PlayerType.PLAYER} />
          ))}
        </div>
        <div className="bar-cage-opponent">
          {times(bar.opponent, (i) => (
            <Checker key={i} owner={PlayerType.OPPONENT} />
          ))}
        </div>
      </div>
    </div>
  )
}

function Off({
  off,
  isDropTarget,
  onClick,
}: {
  onClick: () => void
  off: BoardInfo['off']
  isDropTarget: boolean
}) {
  return (
    <div className="board-off rail">
      <div className="off-cage off-cage-opponent">
        <div className="off-count">{off.opponent}</div>
        <div className="off-checkers">
          {times(off.opponent, (i) => (
            <div key={i} className="off-checker" />
          ))}
        </div>
      </div>
      <div className="off-cage off-cage-player">
        <div className={`off-count ${isDropTarget ? 'drop-target' : ''}`}>
          {off.player}
        </div>
        <div
          className="off-checkers"
          onClick={onClick}
          role="button"
          aria-hidden
        >
          {times(off.player, (i) => (
            <div key={i} className="off-checker" />
          ))}
        </div>
      </div>
    </div>
  )
}

function Checker({ owner, label }: { owner: PlayerType; label?: number }) {
  return (
    <div className={`checker checker-${owner}`}>
      {label && <div className="count-label">{label}</div>}
    </div>
  )
}
