import {
  BAR_POINT,
  Dice,
  getMovesWithSteps,
  InProgressGameState,
  Model,
  MoveInfo,
  OFF_POINT,
  opponent,
  opponentPoint,
  Player,
  playMoves,
  playTurn,
  rollDice,
  User,
} from '@kfiroo/backgammon-core'
import { DocumentReference } from 'firebase/firestore'
import { last } from 'lodash'

import { requestToken, TokenResult } from '../../firebase/messaging'
import { BaseGameApi, BoardInfo, PlayerType } from './BaseGameApi'

export class InProgressGameApi extends BaseGameApi<InProgressGameState> {
  constructor(ref: DocumentReference, model: Model, user: User) {
    super(ref, model, user)
  }

  protected get playerBoard(): InProgressGameState['board'][Player] {
    return this.gameState.board[this.playerIndex as Player]
  }

  protected get opponentBoard(): InProgressGameState['board'][Player] {
    return this.gameState.board[opponent(this.playerIndex as Player)]
  }

  protected playerPoint(point: number): number {
    if (point < OFF_POINT || point > BAR_POINT) {
      throw new Error('Point out of range')
    }
    return this.playerBoard[point]
  }

  protected opponentPoint(point: number): number {
    if (point < OFF_POINT || point > BAR_POINT) {
      throw new Error('Point out of range')
    }
    return this.opponentBoard[opponentPoint(point)]
  }

  async setupNotifications(): Promise<TokenResult> {
    const user = this.model.players[this.playerIndex]
    if (!user) {
      console.log('Not a player, no notifications')
      return {
        success: false,
        error: 'Not a player',
      }
    }

    const result = await requestToken()
    console.log('result', JSON.stringify(result, null, 4))

    if (result.success) {
      if (user.messagingToken === result.token) {
        console.log('Already have a token', user.messagingToken)
        return {
          success: true,
          token: user.messagingToken,
        }
      }

      user.messagingToken = result.token
      this.saveGameModel()
    }
    return result
  }

  getBoard(): BoardInfo {
    return this.buildBoardInfoFromState(this.gameState)
  }

  getTurn(): PlayerType {
    return this.gameState.turn === this.playerIndex
      ? PlayerType.PLAYER
      : PlayerType.OPPONENT
  }

  getDice(): Dice | null {
    return this.gameState.dice
  }

  getRules(): Model['rules'] {
    return this.model.rules
  }
}

interface UndoStackItem {
  model: Model
  moves: MoveInfo[]
  steps: number[]
}

export class PlayingGameApi extends InProgressGameApi {
  private steps: number[] = []
  private moves: MoveInfo[] = []
  private undoStack: UndoStackItem[] = []

  constructor(ref: DocumentReference, model: Model, user: User) {
    super(ref, model, user)
    if (this.gameState.dice) {
      this.steps = this.getOwnSteps()
      this.moves = []

      this.snapshot()
    }
  }

  rollDice() {
    this.model = rollDice(this.model)
    this.saveGameModel()
  }

  getValidMoves() {
    return getMovesWithSteps(this.model, this.steps)
  }

  move(from: number, to: number) {
    const availableMoves = this.getValidMoves()
    const movesList = availableMoves[from]
    const sortedMoveList = movesList
      .filter((moves) => last(moves)?.to === to)
      .sort((a, b) =>
        a.some((m) => m.isHit) ? -1 : b.some((m) => m.isHit) ? 1 : 0,
      )

    const moves = sortedMoveList[0]

    this.playMoves(moves)
  }

  isOpenOpponent(point: number): boolean {
    return this.opponentPoint(point) === 1
  }

  canEndTurn(): boolean {
    if (!this.gameState.dice) {
      return false
    }
    if (this.steps.length === 0) {
      return true
    }
    const availableMoves = this.getValidMoves()
    return Object.keys(availableMoves).length === 0
  }

  endTurn() {
    try {
      const model = playTurn(this.initialModel, this.moves)
      if (model.gameState.state === 'in-progress') {
        this.model = model
      }
      this.saveGameModel(model)
    } catch (err) {
      console.error('endTurn', err)
    }
  }

  canUndo(): boolean {
    return this.undoStack.length > 1
  }

  undo() {
    if (this.undoStack.length <= 1) {
      return
    }

    const { model, moves, steps } = this.undoStack.pop() as UndoStackItem

    this.model = model
    this.moves = moves
    this.steps = steps
  }

  private snapshot() {
    this.undoStack.push({
      model: this.model,
      moves: this.moves,
      steps: this.steps,
    })
    console.log('snapshot', this.undoStack.length)
  }

  private getOwnSteps(): number[] {
    if (!this.gameState.dice) {
      return []
    }
    if (this.gameState.dice.die1 === this.gameState.dice.die2) {
      return [
        this.gameState.dice.die1,
        this.gameState.dice.die1,
        this.gameState.dice.die1,
        this.gameState.dice.die1,
      ]
    }
    return [this.gameState.dice.die1, this.gameState.dice.die2]
  }

  private playMoves(moves: MoveInfo[]) {
    this.snapshot()

    this.moves = [...this.moves, ...moves]
    const { model, steps } = playMoves(this.model, moves, this.steps)
    this.model = model
    this.steps = steps
  }
}

export class ReadOnlyGameApi extends InProgressGameApi {
  constructor(ref: DocumentReference, model: Model, user: User) {
    super(ref, model, user)
  }
}

function isUserPlaying(model: Model, user: User): boolean {
  if (model.gameState.state !== 'in-progress') {
    throw new Error('Game is not in progress')
  }
  const playerIndex = model.players.findIndex((u) => u?.email === user.email)
  return playerIndex === model.gameState.turn
}

export const getInProgressGameApi = (
  ref: DocumentReference,
  model: Model,
  user: User,
): InProgressGameApi => {
  const searchParams = new URLSearchParams(document.location.search)
  const playerIndex = searchParams.get('p')
  if (playerIndex === '0' || playerIndex === '1') {
    return new PlayingGameApi(
      ref,
      model,
      model.players[playerIndex === '0' ? 0 : 1]!,
    )
  }

  if (isUserPlaying(model, user)) {
    return new PlayingGameApi(ref, model, user)
  }
  return new ReadOnlyGameApi(ref, model, user)
}
